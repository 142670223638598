import { redirect } from 'react-router-dom';
import { getActiveManager } from '@lib/session';
import { comesFromInvoice } from '@lib/url';

async function guestLoader() {
  const params = new URLSearchParams(window.location.search);

  const manager = getActiveManager();
  const authenticated = await manager.authenticated();
  console.log('[LOADER] Public loader', { authenticated });

  if (!authenticated) {
    return null;
  }

  console.log(
    "[LOADER] Trying to use a public route but the current session isn't empty.",
    'Redirecting to the /home page',
  );

  // The user just scann the QR code in the invoice.
  // Redirect the user to the instructions to scan
  // the right QR code.
  if (comesFromInvoice(params)) {
    return redirect('/qr-canjeado?badRequestCode=invalid');
  }

  return redirect('/home');
}

export default guestLoader;
