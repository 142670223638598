export function comesFromInvoice(params: URLSearchParams): boolean {
  const source = params.get('utm_source') === 'dealer';
  const medium = params.get('utm_medium') === 'boleta';
  const campaign = params.get('utm_campaign') === 'boleta';

  // The user just scann the QR code in the invoice.
  // Redirect the user to the instructions to scan
  // the right QR code.
  return medium && source && campaign;
}
